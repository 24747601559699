import React from "react";
import { Link } from "gatsby";

import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import SeoCookiesPolicy from "../components/SeoCookiesPolicy";
import CookieBanner from "../components/CookieBanner";

const CookiePolicyPage = () => {
  return (
    <>
      <div id="top" name="top" className="bg-gray">
        <SeoCookiesPolicy />
        <Header />
        <main className="container flex flex-col h-fit w-full py-10 md:py-10 xl:py-10 min-h-withHeaderFooter">
          <div className="flex flex-col gap-5 text-center mb-10">
            <h1 className="text-4xl">Cookie Notice</h1>
            <h4 className="text-xl">Updated November 3, 2022</h4>
          </div>

          <p className="mb-10">The following Cookie Policy is valid from and was last updated on November 3, 2022.</p>

          <div className="mb-10">
            <p className="body2">
              This Cookie Notice explains how WIN GRAVITY SRL ("Slashscore," "we," "us," and "our") uses cookies, pixel
              tags, local storage, and other similar technologies (collectively referred to as “Cookies”) to recognize
              you when you visit our public websites slashscore.com, slashscore.ai and subdomains (the "Websites"), and
              Slashscore’s online software platform, including any related APIs provided by Slashscore&nbsp;
              <a href="https://app.slashscore.com/" target="_blank" rel="noreferrer noopener" className="underline">
                https://app.slashscore.com/
              </a>
              &nbsp; (collectively, “Services”). It explains what these technologies are and why we use them, as well as
              your rights to control our use of them.
            </p>
            <p className="body2">
              Please take a look at our&nbsp;
              <Link
                to={`${process.env.HOST}/privacy-policy`}
                className="underline"
                target="_blank"
                rel="noreferrer noopener"
              >
                Privacy Policy
              </Link>
              &nbsp; if you’d like more information about how Slashscore collects, uses, and shares your personal
              information. .
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">What are Cookies?</h2>
            <p className="body2">
              Cookies are small text files or bits of information that can be used by websites to make a user’s
              experience more efficient and that may be stored on, accessed from, and removed from your device when you
              access our websites and our online software platforms. We use cookies to provide you with an optimized web
              experience.
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">Why does Slashscore use Cookies?</h2>
            <p className="body2">
              We use Cookies for several reasons. Some Cookies are required for technical reasons that are essential for
              our Services to operate and to provide user-requested functionality; we refer to these as "Strictly
              Necessary" Cookies. This is described in more detail below.
            </p>

            <table className="table" aria-label="simple table">
              <thead className="text-2xl">
                <tr>
                  <th className="cell">
                    <b>Types of Cookies</b>
                  </th>
                  <th className="cell w-1/2">
                    <b>Description</b>
                  </th>
                  <th className="cell">
                    <b>Domain</b>
                  </th>
                  <th className="cell">
                    <b>Who Serves These Cookies</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="row multiple-rows">Strictly Necessary Cookies</td>
                  <td className="row multiple-rows">
                    Strictly Necessary Cookies are essential to provide you with services available through our Website
                    and Services. Because these Cookies are strictly necessary to deliver the Website and Services to
                    you, they cannot be switched off in our systems. 
                  </td>
                  <td className="row multiple-rows">
                    <ul>
                      <li>www.slashscore.ai </li>
                      <li>www.slashscore.com </li>
                      <li>app.slashscore.ai</li>
                      <li>app.slashscore.com</li>
                    </ul>
                  </td>
                  <td className="row multiple-rows">Slashscore </td>
                </tr>
                <tr>
                  <td className="row multiple-rows">Functional Cookies</td>
                  <td className="row multiple-rows">
                    These cookies enable us to provide enhanced functionality and personalization. They may be set by us
                    or by third-party providers whose services we have added to our pages. If you do not allow these
                    cookies then some or all of these services may not function properly.
                  </td>
                  <td className="row multiple-rows">
                    <ul>
                      <li>www.slashscore.com </li>
                      <li>www.slashscore.ai </li>
                      <li>app.slashscore.ai</li>
                      <li>app.slashscore.com</li>
                    </ul>
                  </td>
                  <td className="row multiple-rows">Slashscore </td>
                </tr>

                <tr>
                  <td className="row multiple-rows">Analytics Cookies</td>
                  <td className="row multiple-rows">
                    These Cookies allow us to count visits and traffic sources so we can measure and improve the
                    performance and functionality of our site. They help us know which pages are the most and least
                    popular and see how visitors move around the site. If you do not allow these Cookies we will not
                    know when you have visited our site in order to monitor performance.
                  </td>
                  <td className="row multiple-rows">
                    <ul>
                      <li>www.slashscore.com </li>
                      <li>www.slashscore.ai </li>
                      <li>app.slashscore.ai</li>
                      <li>app.slashscore.com</li>
                    </ul>
                  </td>
                  <td className="row multiple-rows">Slashscore </td>
                </tr>
              </tbody>
            </table>

            <p className="body2 mt-10">
              Slashscore may use advertising cookies to assist us in attributing your creation of a Slashscore account
              with marketing or advertising campaigns you may have interacted with, for example, where a Slashscore
              partner provides an affiliate link that you click on to reach our Website.
            </p>

            <p className="body2">For more information on the specific types of Cookies, please see them below:</p>

            <ul className="list-disc pl-14">
              <li className="body2">Invitation code received through the invitation system / expiration 7 days</li>
              <li className="body2">
                The username that the forgot password process has been initiated for / expiration 1 hour
              </li>
              <li className="body2">
                Code used to protect against XSRF when creating/updating an integration / expiration 5 minutes
              </li>
              <li className="body2">
                Url that the user is redirected to once login/signup is successful / expiration: Session
              </li>
              <li className="body2">Session JWT token / expiration: 30 days</li>
              <li className="body2">
                Code used to protect against XSRF when signing up using a third-party service / expiration: Session
              </li>
            </ul>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">How can you control Cookies?</h2>
            <p className="body2">
              You have the right to decide whether to accept or reject Cookies. Slashscore provides settings for you to
              update your Cookie preferences on our Website and Services. These settings can be found in the footer of
              our Website.
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">How often will we update this Cookie Notice?</h2>
            <p className="body2">
              We may update this Cookie Notice from time to time in order to reflect, for example, changes to the
              Cookies we use or for other operational, legal or regulatory reasons. Please return to this Cookie Notice
              on a regular basis to stay informed about our use of cookies and related technologies.
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">Where can you get further information?</h2>
            <p className="body2">
              If you have any questions about our use of Cookies, please email us at team@slashscore.com. This Cookie
              Policy applies to the following domains: slashscore.com, slashscore.ai and subdomains.
            </p>
          </div>
        </main>
        <Footer arrowLink="/privacy-policy#top" />
        <CookieBanner />
      </div>
    </>
  );
};

export default CookiePolicyPage;
